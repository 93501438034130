import React, { Component, useEffect, useState } from 'react';
import './assets/css/style.css';
import './assets/css/core.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Login from './components/Login';
import Body from './components/Body';
import Left from './components/Left';
import User from './components/User';
import NotFound from './NotFound';
import { Container, Row } from 'reactstrap';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Explore from './components/Explore';
import Notification from './components/Notification';
import Message from './components/Message';
import Transactions from './components/Transactions';
import Collections from './components/Collections';
import Profile from './components/Profile';
import Store from './components/Store';
import Categories from './components/Categories';
import ItemDetail from './components/ItemDetail';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import Post from './components/Post';
import Posts from './components/Posts';
import Apply from './components/Apply';
import Pages from './components/Pages';
import Renew from './components/Renew';
import Forgot from './components/Forgot';
import axios from 'axios';
<link rel="icon" type="image/png" href="https://panel.kinkyfans.net/secret.webp" />
function App() {
  let useripadd = "";
  let goster = 0;
  //let userid = sessionStorage.getItem('userid');
  //document.title = "KinkyFans";
  localStorage.setItem('stockimg', 'https://panel.kinkyfans.net/secret.webp');
  localStorage.setItem('dil', 'en');
  localStorage.setItem('localDocs', 'https://panel.kinkyfans.net');
  let dil = localStorage.getItem('dil');
  let localDocs = localStorage.getItem('localDocs');
  console.log(localDocs);
  const [userData, setUserData] = useState([]);
  const fetchUserData = () => {
    fetch(localDocs+'/rn-adaptor/l-user?id=1')
      .then(responseUD => responseUD.json())
      .then(userData => {setUserData(userData);})
      .catch(errorUD => console.log('Fetch error : ', errorUD));
  }
  const [langData, setLangData] = useState([]);const fetchLangData = () => {
    fetch(localDocs+'/rn-adaptor/lang.php?lang=' + dil)
    .then(rsLng => rsLng.json())
    .then(langData => {setLangData(langData);
      document.getElementById('loadingScreen').style.display = 'none';})
      .catch(errLng => console.log('Ferror : ', errLng));}
  useEffect(() => {
    fetchUserData();
    fetchLangData();
  }, []);
  //console.log(userData);
  function ldMenu(type){
    if(type=="close"){
      document.getElementById('ldMenu').style.left="-300px";
      document.getElementById('windowBlur').style.display="none";
    }else{
      document.getElementById('ldMenu').style.left="0px";
      document.getElementById('windowBlur').style.display="block";
    }
  }
  let userid = localStorage.getItem('userid');
  let mobilScreenDisplay = 'block';
  if (window.location.pathname == "/login") { mobilScreenDisplay = 'none'; }else{ mobilScreenDisplay = 'block'}
  function logout(){
    localStorage.setItem('userid', '0');
    window.location.href='/login';
  }
  return (
    <div >
      <div id='loadingScreen'>
        <div className="spinner"></div>
        <img src={require('../src/assets/images/klogo_dark.webp')}/>
      </div>
      <div className='mobilScreen' style={{ display : mobilScreenDisplay}}>
          <div className='mobilMenuArea'>
              <div className='mobilMenuItems'><a href='/'><img src={require('../src/assets/icons/home.webp')} /></a></div>
              <div className='mobilMenuItems'><a href='/message'><img src={require('../src/assets/icons/message.webp')} /></a></div>
              <div className='mobilMenuItems'><a  onClick={() => ldMenu('open')}><img src={require('../src/assets/icons/more.webp')} /></a></div>
              <div className='mobilMenuItems'><a href='/notifications'><img src={require('../src/assets/icons/notifications.webp')} /></a></div>
              <div className='mobilMenuItems'><a href={'/user-detail/'+userid}><img src={require('../src/assets/icons/profile.webp')} /></a></div>
          </div>
      </div>
      <div className='leftDetailMenu' id='ldMenu'>
        <span className='ldmClose' onClick={()=>ldMenu('close')}>X</span>
        <div className='ldUser'>
          <div className='ldUserInfo'>
            <img src={userData.pp} />
            <p>{userData.name} {userData.surname}</p>
            <a>@{userData.username}</a>
          </div>
          <a href='' className='ldUserData'>
            <h6>{userData.following}</h6>
            <p>{langData.following}</p>
          </a>
          <a href='' className='ldUserData'>
            <h6>{userData.followers}</h6>
            <p>{langData.follower}</p>
          </a>
        </div>
        <ul className="ldMenu">
          <div className='ldMenuGroup'>
            <li><a href="/transactions"><img src={require('../src/assets/icons/profile.webp')} /> <p> {langData.add_funds}</p></a></li>  
          </div>
          <div className='ldMenuGroup'>
            <li><a href={"/user-detail/"+userid}><img src={require('../src/assets/icons/profile.webp')} /> <p> {langData.profile}</p></a></li>
            <li><a href={"/message"}><img src={require('../src/assets/icons/message.webp')} /> <p> {langData.message}</p></a></li>
            <li><a href={"/explore"}><img src={require('../src/assets/icons/explore.webp')} /> <p> {langData.explore}</p></a></li>
            <li><a href="/collections"><img src={require('../src/assets/icons/collections.webp')} /> <p> {langData.collections}</p></a></li>
            <li><a href="/transactions"><img src={require('../src/assets/icons/money.webp')} /> <p> {langData.transactions}</p></a></li>
            {userData.isCreator == '1' ? (
            <li><a href="/posts"><img src={require('../src/assets/icons/creators.webp')} /> <p> {langData.my_posts}</p></a></li>
            ):(
              <li><a href="/apply"><img src={require('../src/assets/icons/creators.webp')} /> <p> {langData.apply_as_creator}</p></a></li>
            )}
            <li><a href="/profile"><img src={require('../src/assets/icons/filter.webp')} /> <p> {langData.settings}</p></a></li>
          </div>
          {/*<div className='ldMenuGroup'>
            <li><a href="/"><img src={require('../src/assets/icons/cards.webp')} /> <p> My Cards(to subscribe)</p></a></li>
          </div>*/}
          <div className='ldMenuGroup'>
            <li><a href="/"><img src={require('../src/assets/icons/lightmode.webp')} /> <p> {langData.light_mode}</p></a></li>
            <li><a href="/message/1"><img src={require('../src/assets/icons/support.webp')} /> <p> {langData.support}</p></a></li>
          </div>
          <li><a onClick = {()=>logout()}><img src={require('../src/assets/icons/logout.webp')} /> <p> {langData.logout}</p></a></li>
        </ul>
      </div>
      <Container>
        <div className='windowBlur' id='windowBlur' onClick={()=>ldMenu('close')}></div>
        <Row>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Body langData={langData}/>} />
              <Route path="/:guserid" element={<Body langData={langData} />} />
              <Route path="/login" element={<Login />} />
              <Route path="/user-detail/:id" element={<User langData={langData} />} />
              <Route path="/explore" element={<Explore langData={langData} />} />
              <Route path="/notification" element={<Notification langData={langData} />} />
              <Route path="/message" element={<Message langData={langData} />}/>
              <Route path="/message/:uniqid" element={<Message langData={langData} />} />
              <Route path="/transactions" element={<Transactions langData={langData} />} />
              <Route path="/collections" element={<Collections langData={langData} />} />
              <Route path="/profile" element={<Profile langData={langData} />} />
              <Route path="/post/:id" element={<Post langData={langData} />} />
              <Route path="/posts" element={<Posts langData={langData} />} />
              <Route path="/posts/:uniqid" element={<Posts langData={langData} />} />
              <Route path="/Apply/" element={<Apply langData={langData} />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/store" element = {<Store langData={langData} />} />
              <Route path="/categories" element = {<Categories langData={langData} />} />
              <Route path="/product" element = {<ItemDetail langData={langData} />} />
              <Route path="/cart" element = {<Cart langData={langData} />} />
              <Route path="/checkout" element = {<Checkout langData={langData} />} />
              <Route path="/pages" element = {<Pages langData={langData} />} />
              <Route path="/pages/:id" element = {<Pages langData={langData} />} />
              <Route path="/renew/" element = {<Renew langData={langData} />} />
              <Route path="/renew/:id" element = {<Renew langData={langData} />} />
              <Route path="/forgot/:id" element = {<Forgot langData={langData} />} />
              <Route path="/forgot/:id" element = {<Forgot langData={langData} />} />
            </Routes>
          </BrowserRouter>
        </Row>
      </Container>
    </div>
  );
}

export default App;
