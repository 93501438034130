import React, { Component, useEffect, useState } from 'react';
/*import '../assets/css/store.css';*/
import StoreHead from './subComponents/StoreHead';
import StoreFooter from './subComponents/StoreFooter';
function Store( {langData} ) {
    const [userData, setUserData] = useState([]);
    const pp = require('../assets/images/klogo.webp');
    let lastImg = "img1";
    function chImage(gid){
        document.getElementById(gid).classList.add('idlaSelected');document.getElementById(lastImg).classList.remove('idlaSelected');
        document.getElementById('mainImg').src = document.getElementById(gid).src;
        lastImg = gid;
    }
    return (
        <div className='storePage'>
            <StoreHead datas={StoreHead} langData={langData} />

            <div className='containers'>
                <div className='breadcrump'>
                    <a href='/store'>{langData.home}</a> <a> > </a> <a>{langData.checkout}</a>
                </div>
                <div className='shoppingCart'>
                    <div className='scLeft'>
                        <h1>{langData.checkout}</h1>
                        <form className='scForm'>
                            <div className='scFormItem'>
                                <label>{langData.name}</label>
                                <input type='text' />
                            </div>
                            <div className='scFormItem'>
                                <label>{langData.surname}</label>
                                <input type='text' />
                            </div>
                            <div className='scFormItem'>
                                <label>{langData.mail}</label>
                                <input type='text' />
                            </div>
                            <div className='scFormItem'>
                                <label>{langData.phone}</label>
                                <input type='text' />
                            </div>
                            <div className='scFormItem'>
                                <label>{langData.state}</label>
                                <input type='text' />
                            </div>
                            <div className='scFormItem'>
                                <label>{langData.district}</label>
                                <input type='text' />
                            </div>
                            <div className='scFormItem2'>
                                <label>{langData.address}</label>
                                <input type='text' />
                            </div>
                            <div className='scFormItem2'>
                                <label>{langData.order_note}</label>
                                <input type='text' />
                            </div>
                        </form>
                    </div>
                    <div className='scRight'>
                        <div className='scrTotals'>
                            <div className='scrTotal'>
                                <span>Item Name x 1 </span>
                                <p>56.55€</p>
                            </div>
                            <div className='scrTotal'>
                                <span>Item Name x 2 </span>
                                <p>56.55€</p>
                            </div>
                        </div>
                        <div className='scrTotals'>
                            <div className='scrTotal'>
                                <span>{langData.total} </span>
                                <p>56.55€</p>
                            </div>
                            <div className='scrTotal'>
                                <span>{langData.coupon_code} </span>
                                <p>10.00€</p>
                            </div>
                            <div className='scrTotal'>
                                <span>{langData.shipping} </span>
                                <p>0.00€</p>
                            </div>
                            <div className='scrTotal'>
                                <span>{langData.total} </span>
                                <p>46.55€</p>
                            </div>
                        </div>
                        <div className='paymentType'>
                            <p>{langData.payment_type}</p>
                            <select>
                                <option>{langData.bank_transfer}</option>
                                <option>{langData.credit_card}</option>
                                <option>Kinkyfans {langData.money}</option>
                            </select>
                        </div>
                        <a className='scrCheckout'>{langData.checkout}</a>
                    </div>

                </div>

            </div>
            <StoreFooter datas={StoreFooter} langData={langData} />
        </div>
    )
}
export default Store;
