import React, { Component, useEffect, useState } from 'react';
/*import '../assets/css/store.css';*/
import StoreHead from './subComponents/StoreHead';
import StoreFooter from './subComponents/StoreFooter';
function Store({langData}) {
    const [userData, setUserData] = useState([]);
    const pp = require('../assets/images/klogo.webp');
    return (
        <div className='storePage'>
            <StoreHead datas = {StoreHead} langData = {langData}/>
            <div className='storeBanner'>
                <img src={require('../assets/images/storeBanner.webp')} />
            </div>
            
            <div className='containers'>
                <div className='storeBody'>
                    <p>{langData.store_main_text}</p>
                    <div className='sbExploreArea'>
                        <h5>Explore It All</h5>
                        <a>{langData.view_all}</a>
                        <div className='sbeaItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                        </div>
                        <div className='sbeaItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                        </div>
                        <div className='sbeaItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                        </div>
                        <div className='sbeaItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                        </div>
                        <div className='sbeaItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                        </div>
                        <div className='sbeaItem'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                        </div>
                    </div>
                    <div className='sbExploreArea'>
                        <h5>Best Sellers</h5>
                        <a>{langData.view_all}</a>
                        <div className='sbeaItem2'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                        <div className='sbeaItem2'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                        <div className='sbeaItem2'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                        <div className='sbeaItem2'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                        <div className='sbeaItem2'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                        <div className='sbeaItem2'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                        <div className='sbeaItem2'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                        <div className='sbeaItem2'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                        <div className='sbeaItem2'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                        <div className='sbeaItem2'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                        <div className='sbeaItem2'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                        <div className='sbeaItem2'>
                            <img src={require('../assets/images/product.webp')} />
                            <p>Collars & Leashes </p>
                            <span className='priceNew'>55.56$</span>
                            <span className='priceOld'>66.67$</span>
                        </div>
                    </div>
                    <div className='sbBannerArea'>
                        <img src={require('../assets/images/lback4.webp')} />
                    </div>
                </div>
            </div>
            <StoreFooter datas = {StoreFooter} langData = {langData} />
        </div>
    )
}
export default Store;
