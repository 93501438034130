import React, { Component, useEffect, useState } from 'react'
import Slider from 'react-slick';
import Story from './subComponents/Story';


function Right(){
    let userid = localStorage.getItem('userid');
    let localDocs = localStorage.getItem('localDocs');
    const [suggestData, setSuggestData] = useState([]);
    const fetchSuggestData = () => {
        fetch(localDocs+'/rn-adaptor/l-suggestions?id='+userid+'&searchKey='+searchKey+'&type=right')
            .then(responseSGG => responseSGG.json())
            .then(suggestData => setSuggestData(suggestData))
            .catch(errorSGG => console.error('Fetch error : ', errorSGG));
        }
    useEffect(() => {
        fetchSuggestData();
    }, []);
    let searchKey = "";
    function runSearch(){
        searchKey = document.getElementById('raSearchInput').value;
        fetchSuggestData();
    }
    function NextArrow(props) { const { className, style, onClick } = props; return ( <div className={className} style={{ ...style, display: "block", background: "transparent", top: "-35px", right : "20px", fontSize : '20px' }} onClick={onClick} /> );}
    function PrevArrow(props) { const { className, style, onClick } = props; return ( <div className={className} style={{ ...style, display: "block", background: "transparent", top: "-35px", left: "auto", right : "55px" }} onClick={onClick} /> );}
    const settings = {
        dots: false, infinite: false, speed: 500, slidesToShow: 5, slidesToScroll: 5, vertical: true, verticalSwiping: false, nextArrow: <NextArrow />, prevArrow: <PrevArrow />,
        responsive: [
            {breakpoint: 1024, settings: {slidesToShow: 3, slidesToScroll: 3, infinite: true, dots: true } },
            {breakpoint: 600, settings: {slidesToShow: 2, slidesToScroll: 2, initialSlide: 2 } },
            {breakpoint: 480, settings: {slidesToShow: 1, slidesToScroll: 1 }}
        ]
    };
    return (
        
        <div className='rightArea'>
            <div className='raSearch'>
                <input onKeyUp={()=>runSearch()} id='raSearchInput' placeholder={'Search'}/>
            </div>
            <div className='raSuggestions'>
                <div className='rasTitle'>
                    <h4>Suggestions</h4>
                </div>
                <div className='rasUsers'>
                    {suggestData ? (
                        <div>
                            {suggestData.map(suggests => (
                                <div key={suggests.id}>
                                    <a key={suggests.id} href={'/user-detail/'+suggests.id} className='rasUser' style={{ float: 'left', width: '100%', background: `url(${suggests.background})`, }}>
                                        <div className='rasUserCover'></div>
                                        <div className='rasUserData'>
                                            <img src={suggests.pp} />
                                            <p>{suggests.name} {suggests.surname}</p>
                                            <span>@{suggests.username}</span>
                                        </div>
                                    </a>
                                </div>
                            ))}
                            </div>
                    ):''}
                </div>
            </div>
            
            <div style={{display:'none'}} className='rasStories'>
                <Story datas = {suggestData} />
            </div>
            <div className='rasPolicies'>
                <ul>
                    <li><a href=''>FAQ</a></li>
                    <li><a href=''>Privacy Policy</a></li>
                    <li><a href=''>Terms & Conditions</a></li>
                    <li><a href=''>Cookie Policy</a></li>
                </ul>
            </div>
        </div>
    )
}
export default Right;