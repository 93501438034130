import React, { Component, useEffect, useState } from 'react';
/*import '../assets/css/store.css';*/
function menuOp(type, mid){
    if(type == "open"){document.getElementById(mid).style.display="block";}else{document.getElementById(mid).style.display="none";}
}
function Header({langData}) {
    const [userData, setUserData] = useState([]);
    const pp = require('../../assets/images/klogo.webp');
    return (
        <div className='storeHeader'>
            <div className='storeHeaderBand'>
                <a>{langData.store_head_text}</a>
            </div>
            <div className='storeHeaderTopMenu'>
                <div className='containers'>
                    <div className='spaceBetween'>
                        <div className='shmLogo'>
                            <img src={pp} />
                        </div>
                        <div className='shmSearch'>
                            <input placeholder={langData.store_search_text} />
                            <img src={require('../../assets/icons/search.webp')} />
                        </div>
                        <div className='shmFunction'>
                            <a><img src={require('../../assets/icons/profile.webp')} /></a>
                            <a><img src={require('../../assets/icons/cart.webp')} /><span>3</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='storeHeaderMenu'>
                <div className='containers'>
                    <div className='shmCenter'>
                        <a>{langData.just_getting_started}</a>
                        <a>{langData.choose_your_path}</a>
                        <a>{langData.find_your_fetish}</a>
                        <a onMouseOver={()=>menuOp('open','menu1')} onMouseOut={()=>menuOp('close','menu1')} >For Men
                            <div id='menu1' className='shmSubMenu'>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                            </div>
                        </a>
                        <a onMouseOver={()=>menuOp('open','menu2')} onMouseOut={()=>menuOp('close','menu2')} >Explore It All
                            <div id='menu2' className='shmSubMenu'>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                            </div>
                        </a>
                        <a>{langData.sale}</a>
                        <a>{langData.help}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header;
