import React, { Component, useEffect, useState } from 'react';
/*import '../assets/css/store.css';*/
function Header({langData}) {
    const [userData, setUserData] = useState([]);
    const pp = require('../../assets/images/klogo.webp');
    return (
        <div className='sbFooter'>
            <div className='containers'>
                <div className='sbFooterSocial'>
                    <h5>{langData.follow_us}</h5>
                    <a><img src={require('../../assets/icons/socialX.webp')} /></a>
                    <a><img src={require('../../assets/icons/socialFacebook.webp')} /></a>
                    <a><img src={require('../../assets/icons/socialTiktok.webp')} /></a>
                    <a><img src={require('../../assets/icons/socialInstagram.webp')} /></a>
                </div>
                <div className='sbFooterItem'>
                    <h5>{langData.shop_by}</h5>
                    <a>Find Your Fetish</a>
                    <a>Choose Your Path</a>
                    <a>Best Sellers</a>
                    <a>Gift Cards</a>
                    <a>New Arrivals</a>
                    <a>Kin Store Exclusive</a>
                </div>
                <div className='sbFooterItem'>
                    <h5>{langData.community}</h5>
                    <a>Affiliates</a>
                    <a>Discord</a>
                    <a>Newsletter</a>
                    <a>Konnektor</a>
                    <a>About Us</a>
                </div>
                <div className='sbFooterItem'>
                    <h5>{langData.information}</h5>
                    <a>Help & FAQ</a>
                    <a>Contact Us</a>
                    <a>Shipping</a>
                    <a>Returns</a>
                    <a>Privacy Policy</a>
                    <a>Terms & Conditions</a>
                </div>
                <div className='sbFooterCopy'>
                    <p> &copy; 2024 Kinky Store, Powered by KinkyFans </p>
                </div>
            </div>
        </div>
    )
}
export default Header;
